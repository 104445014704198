import React from "react";
import { mail, phone, youngtv, locationIcon, logo } from "../../assets";
import "./NavBar.css";

const NavBar = () => {
  return ( 
    <nav id="top">
      <div className="wrapper wrap">
        <div className="group">
          <a className="logos" href="Youngtv.lk">
            <img src={youngtv} alt="" />{" "}
            <img className="Ylogo" src={logo} alt="" />
          </a>

          <ul>
            <li>
              <a href="#">
                <img src={phone} alt="" />
                <span className="contact"> Free call:</span>{" "}
                <span className="">+94 114 756 001</span>
              </a>
            </li>
            <li>
              <a href="#">
                <img src={mail} alt="" />
                <span className="contact"> Email:</span>{" "}
                <span className="">info@youngtv.lk</span>
              </a>
            </li>
            <li>
              <a href="#">
                <img src={locationIcon} alt="" />
                <span className="contact"> Location:</span>{" "}
                <span className="">Battaramulla, Sri lanka</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
