import React from 'react'
import './AboutUs.css'
import  { logo } from '../../assets'
import { Fade } from "react-awesome-reveal";

const AboutUs = () => {
  return (
    <div className="wrapper">
      <Fade delay="2">
    <section id='about'>
    <a className='abtimg' href="#"><img className='aboutlogo' src={logo} alt="" /></a>
      <h2>About us</h2>
      <div className="para">
      <p>Welcome to the heartbeat of the youth – Young TV, a revolutionary venture by Young Asia Media Corporation (PVT) LTD. At Young TV,we transcend the conventional and redefine television, crafting an experience that resonates with the vibrant spirit of today's generation.</p></div>
    </section>
    </Fade>
    </div>
  )
}

export default AboutUs