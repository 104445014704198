import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './Featured.css';
import { Fade } from "react-awesome-reveal";
import { arrL, arrR, play, thumbnail1, thumbnail2, thumbnail3 } from '../../assets';

const videoIds = [
  'P5rBJ746F1g',
  'P5rBJ746F1g',
  'P5rBJ746F1g',
  // ...
];

const items = [
  { id: 1, thumbnail: thumbnail1 },
  { id: 2, thumbnail: thumbnail2 },
  { id: 3, thumbnail: thumbnail3 },
  { id: 4, thumbnail: thumbnail3 },
  { id: 5, thumbnail: thumbnail2 },
  { id: 6, thumbnail: thumbnail1 },
];


const CustomArrow = ({ onClick, src, ariaLabel, className, style }) => {
  return (
    <img
      src={src}
      onClick={onClick}
      aria-label={ariaLabel}
      className={className}
      style={{
        width: '13px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        ...style,
      }}
    ></img>
  );
};

const CustomRightArrow = ({ onClick }) => {
  return (
    <CustomArrow
      src={arrR}
      onClick={onClick}
      ariaLabel="Go to next slide"
      className="react-multi-carousel__arrow react-multi-carousel__arrow--right"
      style={{
        right: '2px',
      }}
    />
  );
};

const CustomLeftArrow = ({ onClick }) => {
  return (
    <CustomArrow
      src={arrL}
      onClick={onClick}
      ariaLabel="Go to previous slide"
      className="react-multi-carousel__arrow react-multi-carousel__arrow--left"
      style={{
        left: '2px',
      }}
    />
  );
};










const Featured = () => {

  const [showYouTubeModal, setShowYouTubeModal] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState('');

  const handleShowClick = (index) => {
    setSelectedVideoId(videoIds[index]);
    setShowYouTubeModal(true);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
      slidesToSlide: 1 
    }
  };




  const opts = {
    height: window.innerWidth < 720 ? '250' : '450',
    width: window.innerWidth < 720 ? '400' : '800',
    playerVars: {
      autoplay: 1,
    },
  };






  return (<>
    <section id='featured' className='featured'>
      <Fade>
      <div className="wrapper">
        <h2 className='feature'>Featured Shows</h2>
        <div className="para">
        <p>Young TV, a proud creation of Young Asia Media Corporation (PVT) LTD, is not just a television channel; we are a movement, a celebration of the dynamic energy that defines today's youth.</p>
        </div>
        
        <div>
          <Carousel
            responsive={responsive}
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
            itemClass="carousel-item-padding-10px"
          >
            {items.map((item, index) => (
              <div className="show" key={item.id} onClick={() => handleShowClick(index)}>
                <a className="play" ><img src={play} alt="" /></a>
                <a ><img className='i' src={item.thumbnail} alt="" /></a>
              </div>
            ))}
          </Carousel>

        </div>
      </div>
      </Fade>
    </section>

{showYouTubeModal && (
  <div className="youtube-modal">
    <div className="youtube-modal-content">
      <YouTube className='youtube' videoId={selectedVideoId} opts={opts}  />
      <button className="close-button" onClick={() => setShowYouTubeModal(false)}>&times;</button>
    </div>
  </div>
)}
</>
);
};
export default Featured;
