import React from 'react';
import './ContactUs.css';
import { Fade } from "react-awesome-reveal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';

const ContactUs = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    // Ensure you have the correct access_key and endpoint
    formData.append("access_key", "c8b8751e-85c1-43f7-bcba-b74fdbb9d5ca");

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      }).then((res) => res.json());

      if (res.success) {
        console.log("Success", res);
        toast.success('Message sent successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setResult("Message sent successfully!");
        event.target.reset();
      } else {
        console.log("Error", res);
        setResult(res.message);
        toast.error('Oops! Something went wrong.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error) {
      console.error("Submission error", error);
      setResult("Failed to send message.");
      toast.error('Error sending message. Please try again later.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  return (
    <section id='contactus'>
      <Fade>
        <div className="wrapper">
          <h2>Contact Us</h2>
          <div className="para">
            <p className='description'>Thank you for reaching out to Young TV! Your feedback, inquiries, and collaboration proposals are important to us. Please fill out the form below, and we'll get back to you as soon as possible.</p>
          </div>
          <div className="content-container">
            <div className="left">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="input-group">
                    <label htmlFor="firstname">First Name</label>
                    <input type="text" name='firstname' id="firstname" required />
                  </div>
                  <div className="input-group">
                    <label htmlFor="lastname">Last Name</label>
                    <input type="text" name='lastname' id="lastname" required />
                  </div>
                </div>
                <div className="input-group">
                  <label htmlFor="company">Company</label>
                  <input type="text" name='company' id="company" required />
                </div>
                <div className="input-group">
                  <label htmlFor="email">Email Address</label>
                  <input type="email" name='email' id="email" required />
                </div>
                <div className="input-group">
                  <label htmlFor="message">Your Message</label>
                  <textarea name='message' id="message" rows={8} required></textarea>
                </div>
                <button className='submit-btn' type="submit">SEND</button>
              </form>
            </div>
            <div className="right">

            <p className='title' >General Inquiries:</p>
            <p className='des' >If you have any general questions about Young TV, our programming, or our<br/>mission, feel free to ask.</p>

            <p className='title' >Collaboration and Partnerships:</p>
            <p className='des' >For collaboration opportunities, brand partnerships, and<br/>sponsorship inquiries, please provide some details about your<br/>proposal.</p>

            <p className="title">Content Submissions:</p>
            <p className="des">If you are a content creator and wish to submit your work or propose a collaboration, please provide a brief overview of your content and relevant details.</p>
          </div>
          </div>
        </div>
        <hr />
      </Fade>
      <ToastContainer />
    </section>
  );
};

export default ContactUs;


















