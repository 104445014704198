import './App.css';
import Header from './components/Header/Header';
import Featured from './components/Featured/Featured';
import AboutUs from './components/AboutUs/AboutUs';
import Partners from './components/Partners/Partners';
import Service from './components/Services/Service';
import Footer from './components/Footer/Footer';
import ContactUs from './components/ContactUs/ContactUs';
import NavBar from './components/NavBar/NavBar';
import Nav from './components/Nav/Nav';

function App() {
  return (<>
    <NavBar/>
    <Nav/>
    <Header/>
    <Featured/>
    <AboutUs/>
    <Service/>
    <Partners/>
    <ContactUs/>
    <Footer/>
    </>
  );
}

export default App;
