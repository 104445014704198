import React from 'react'
import './Footer.css'
import {logo, youngfm, youngtv} from '../../assets/'

const Footer = () => {
  return (
    <footer>
      <div className="wrapper back">
      
        <div className="content-container">
         
          <div className="logos">
          <a href="#"><img className="Flogo" src={logo} alt="" /></a><a href="#"><img src={youngtv} alt="" /></a><a href="#"><img src={youngfm} alt="" /></a>
          </div>
          <p>Young TV, a proud creation of Young Asia Media Corporation (PVT) LTD, is not just a television<br/>channel; we are a movement, a celebration of the dynamic energy that defines today's youth.</p>
          <ul>
            <li><a href="#top">Home</a></li>
            <li><a href="#about">About Us</a></li>
            <li><a href="#featured">Shows</a></li>
            <li><a href="https://blog.youngtv.lk/">Blog</a></li>
            <li><a href="#contactus">Contact Us</a></li>
          </ul>
        </div>
      </div>
      <div className="copyright">
        <p>
      Copyright © 2024 DigitalNext (Private) Limited. All Rights Reserved.
      </p>
      </div>
    </footer>
  )
}

export default Footer