import React, { useState } from 'react';
import './Nav.css';
import { close, menu } from '../../assets';

const Nav = () => {

  const [isactive, setIsactive] = useState(false);

  const [activeItem, setActiveItem] = useState('Home');

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  return (<>
    <nav className='navbar'>
      <div className="wrapper container">
      <ul className='dmenu'>
        <li >
          <a href='#header' onClick={() => handleItemClick('Home')} className={`element ${activeItem === 'Home' ? 'active' : ''}`}>
            Home
          </a>
        </li>
        <li >
          <a href='#about' onClick={() => handleItemClick('About Us')} className={`element ${activeItem === 'About Us' ? 'active' : ''}`}>
            About Us
          </a>
        </li>
        <li className={activeItem === 'Shows' ? 'active' : ''}>
          <a href='#featured' onClick={() => handleItemClick('Shows')} className={`element ${activeItem === 'Shows' ? 'active' : ''}`}>
            Shows
          </a>
        </li>
        <li className={activeItem === 'Blog' ? 'active' : ''}>
          <a href='https://blog.youngtv.lk/' onClick={() => handleItemClick('Blog')} className={`element ${activeItem === 'Blog' ? 'active' : ''}`}>
            Blog
          </a>
        </li>
        <li >
          <a href='#contactus' onClick={() => handleItemClick('Contact us')} className={`element ${activeItem === 'Contact us' ? 'active' : ''}`}>
            Contact us
          </a>
        </li>
      </ul>
      


      <div onClick={() => setIsactive(!isactive)} className={`menu-icon ${isactive ? 'active' : ''}`}>
        {isactive ? <i className='fas fa-times'></i> : <i className='fas fa-bars'></i>}
      </div>
      </div>
    </nav>

    <div style={{height: "250px"}} className={`mobile-menu-container ${isactive ? "activeM":""}`}>
      
      <ul className='menu-items'>
        <li><a href="#header">Home</a></li>
        <li><a href="#about">About Us</a></li>
        <li><a href="#featured">Shows</a></li>
        <li><a href="#partners">Blog</a></li>
        <li><a href="#contactus">Contact Us</a></li>
        
      </ul>
    </div>
    </>
  );
};

export default Nav;
